import React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const FourOFour = () => (
    <>
        <Navbar />
        <div className="row">
            <div className="col-md-12 text-center">
                <h1>Oops, den siden finnes ikke!</h1>
                <p>
                    Kanskje har frilanseren vår gjort en feil? Typisk
                    frilanser...
                </p>
                <p>Vent, det er jo jeg som skriver dette!</p>
                <p>
                    Forvirret? Du kan alltids{" "}
                    <a href="/">gå tilbake til forsiden</a>.
                </p>
            </div>
        </div>
        <Footer />
    </>
)

export default FourOFour
